import image1 from "../assets/IMG_orh.jpg";
import image2 from "../assets/IMG_cam.jpg";
import image3 from "../assets/IMG_ram.jpg";
import image4 from "../assets/IMG_sab.JPG";
import image5 from "../assets/IMG_rac.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "MMA, Bodybuilding coach. National Champion in free fighting.\n" +
        "Bodybuilding country finals 2011, 2013, 2014, 2015",
    name: 'Orxan Kazimovski',
    status : 'ATEF GYM '
  },
  {
    image: image2,
    review: 'Team Athlete, European Champion WPC, European Champion IPA, World Champion',
    name: 'Camal Məmmədov',
    status: 'WPC, IPA champion'
  },
  {
    image : image3,
    review:' Ağır atletika üzrə Respublika və Avropa chempionu, 20 illik iş təcrübəsi',
    name: 'Ramid Babayev',
    status: "Prime coach"
  },
  {
    image: image4,
    review: 'Dietoloq, Qadın fitness mütəxəsisi, \n' +
        'Arıqlama programları üzrə mütəxəsis ',
    name: 'Sabina Nabiyeva',
    status: 'Fitness instruktor'
  },
  {
    image: image5,
    review: 'Reabiletoloq, Kinezioterapevt, Fitness konsultant',
    name: 'Rac Hacizadə ',
    status: 'COACH'
  }
];
